import React from "react";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalFooter,
  Button,
} from "@nextui-org/react";

interface WarningModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  message: string;
}

const WarningModal: React.FC<WarningModalProps> = ({
  isOpen,
  onClose,
  title,
  message,
}) => {
  return (
    <Modal
      backdrop="blur"
      isOpen={isOpen}
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          onClose();
        }
      }}
      classNames={{
        wrapper: "z-[100002]",
        backdrop: "z-[100001]",
      }}
    >
      <ModalContent className="relative w-full max-w-[400px] border-none border-foreground/10 bg-[url('https://nextuipro.nyc3.cdn.digitaloceanspaces.com/components-images/abstract-dark-bg4.jpg')] bg-right-bottom">
        <ModalHeader className="flex flex-col pb-0">
          <h1 className="mb-2 text-default-400">{title}</h1>
        </ModalHeader>
        <ModalBody className="relative flex gap-0 p-8">
          <h2 className="inline bg-gradient-to-br from-foreground-800 to-foreground-500 bg-clip-text text-lg font-semibold tracking-tight text-transparent dark:to-foreground-200">
            {message}
          </h2>
        </ModalBody>
        <ModalFooter>
          <div className="flex flex-col gap-4 w-full items-center">
            <div className="w-full mt-2">
              <Button
                fullWidth
                className="border-small border-white/20 bg-white/10 text-white"
                onPress={onClose}
              >
                Continue
              </Button>
            </div>
          </div>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default WarningModal;
