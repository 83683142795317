import React, { createContext, useState, useEffect } from "react";

interface ReleaseContextProps {
  isReleased: boolean;
}

export const ReleaseContext = createContext<ReleaseContextProps>({
  isReleased: false,
});

export const ReleaseProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const releaseDateString = process.env.REACT_APP_RELEASE_DATE!;
  const releaseDate = new Date(releaseDateString);
  const [isReleased, setIsReleased] = useState(new Date() >= releaseDate);

  useEffect(() => {
    if (!isReleased) {
      const now = new Date();
      const timeout = releaseDate.getTime() - now.getTime();
      if (timeout > 0) {
        const timer = setTimeout(() => {
          setIsReleased(true);
        }, timeout);
        return () => clearTimeout(timer);
      } else {
        setIsReleased(true);
      }
    }
  }, [isReleased, releaseDate]);

  return (
    <ReleaseContext.Provider value={{ isReleased }}>
      {children}
    </ReleaseContext.Provider>
  );
};
