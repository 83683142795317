"use client";
import React from "react";
import { TracingBeam } from "components/UI/tracing-beam";
import { Spotlight } from "components/UI/Spotlight";
import { Image } from "@nextui-org/react";

export function PrivacyPolicyExtension() {
  return (
    <div className="w-full h-full overflow-hidden bg-black/[0.96]">
      <div className="w-full min-h-screen h-full overflow-hidden bg-customGray/[0.56]">
        <div className="absolute inset-0 h-full w-full bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:44px_44px]"></div>

        <Spotlight
          className="-top-40 left-0 md:left-60 md:-top-20 z-20"
          fill="#22dcd1"
        />
        <div className="max-w-7xl mx-auto py-20 px-4 md:px-8 lg:px-10 selectable relative z-[24]">
          <h2 className="text-4xl md:text-6xl lg:text-7xl uppercase mb-4 max-w-4xl font-bold bg-clip-text text-transparent bg-gradient-to-b from-synkColor/20 to-synkColor/70">
            Privacy Policy for the Synk Extension
          </h2>
          <p className="text-neutral-400 text-md md:text-2xl max-w-lg mb-10">
            Last Updated: 12-01-2024
          </p>
          <div className="text-neutral-400 text-md md:text-2xl max-w-3xl mb-10">
            At Synk, we value our users’ privacy and are committed to fully
            complying with applicable regulations concerning personal data
            management. This policy aims to explain our dedication to data
            protection, in alignment with Chrome Web Store requirements and
            relevant regulations.
          </div>
        </div>
        <TracingBeam className="px-6 mb-[100px]">
          <div className="max-w-2xl mx-auto antialiased pt-4 relative px-6 md:px-0">
            {privacyPolicyContent.map((item, index) => (
              <div key={`content-${index}`} className="mb-10">
                <h2 className="bg-black shadow-custom text-white rounded-full text-sm w-fit px-4 py-1 mb-4">
                  {item.badge}
                </h2>

                {/* <p className={"text-2xl mb-4"}>{item.title}</p> */}

                <div className="text-sm sm:text-md prose prose-sm dark:prose-invert">
                  {item.description}
                </div>
              </div>
            ))}
          </div>
        </TracingBeam>
      </div>
    </div>
  );
}

const privacyPolicyContent = [
  {
    title: "1. Data Collection",
    description: (
      <>
        <p>
          The Synk extension does not collect, process, or transmit any personal
          or sensitive user data. This means:
        </p>
        <ul className="list-disc pl-5">
          <li>No tracking of browsing activities.</li>
          <li>No data collection via forms or user interactions.</li>
          <li>
            No transmission of information to third-party or external servers.
          </li>
          <li>
            All extension functionalities operate exclusively locally on your
            device.
          </li>
        </ul>
      </>
    ),
    badge: "Data Collection",
    image:
      "https://images.unsplash.com/photo-1464822759023-fed622ff2c3b?auto=format&fit=crop&q=80&w=3540&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    title: "2. Data Usage",
    description: (
      <>
        <p>
          Since Synk does not collect any user data, there is no use or
          exploitation of personal or sensitive information. Your data remains
          entirely under your control and is safeguarded by the extension's
          local functionality.
        </p>
      </>
    ),
    badge: "Data Usage",
    image:
      "https://images.unsplash.com/photo-1519681393784-d120267933ba?auto=format&fit=crop&q=80&w=3540&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    title: "3. Data Sharing",
    description: (
      <>
        <p>
          Synk does not share any data with third parties for commercial,
          analytical, or advertising purposes. In the absence of data
          collection, there is no transmission, sale, or exchange of information
          with any external entities.
        </p>
      </>
    ),
    badge: "Data Sharing",
    image:
      "https://images.unsplash.com/photo-1469474968028-56623f02e42e?auto=format&fit=crop&q=80&w=3506&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    title: "4. Data Security",
    description: (
      <>
        <p>
          Although Synk does not manage user data, we implement secure
          development practices to ensure the extension remains reliable and
          protects your privacy against unauthorized interactions.
        </p>
      </>
    ),
    badge: "Data Security",
    image:
      "https://images.unsplash.com/photo-1464822759023-fed622ff2c3b?auto=format&fit=crop&q=80&w=3540&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    title: "5. Permissions Requested",
    description: (
      <>
        <p>
          Synk operates with the minimal permissions necessary to ensure its
          core functionalities. These include only the permissions strictly
          required for the extension’s local tasks. Under no circumstances do
          these permissions grant access to your personal or sensitive data.
        </p>
      </>
    ),
    badge: "Permissions Requested",
    image:
      "https://images.unsplash.com/photo-1519681393784-d120267933ba?auto=format&fit=crop&q=80&w=3540&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    title: "6. Commitment to Privacy",
    description: (
      <>
        <p>
          Synk fully adheres to Chrome Web Store policies, including those
          related to data privacy and minimal permissions. Our extension is
          designed to provide a secure and private user experience without
          compromising your confidentiality.
        </p>
      </>
    ),
    badge: "Commitment to Privacy",
    image:
      "https://images.unsplash.com/photo-1469474968028-56623f02e42e?auto=format&fit=crop&q=80&w=3506&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    title: "7. Updates to This Policy",
    description: (
      <>
        <p>
          This policy may be updated as needed to reflect changes in regulations
          or the extension’s features. Any updates will be published in the
          dedicated section of our product page on the Chrome Web Store.
        </p>
      </>
    ),
    badge: "Policy Updates",
    image:
      "https://images.unsplash.com/photo-1464822759023-fed622ff2c3b?auto=format&fit=crop&q=80&w=3540&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    title: "8. Contact",
    description: (
      <>
        <p>
          If you have any questions or concerns regarding this policy, please
          contact us at:
        </p>
        <p>
          <strong>Email:</strong>{" "}
          <a href="mailto:access@synk.ws" className="text-blue-400">
            access@synk.ws
          </a>
        </p>
        <p>
          By publishing this policy, Synk guarantees full transparency and
          rigorous respect for user privacy in compliance with Chrome Web Store
          policies.
        </p>
      </>
    ),
    badge: "Contact",
    image:
      "https://images.unsplash.com/photo-1519681393784-d120267933ba?auto=format&fit=crop&q=80&w=3540&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
];
