import React, { useEffect, useRef, useState, useLayoutEffect } from "react";
import { observer } from "mobx-react-lite";
import { runInAction } from "mobx";
import Joyride, { CallBackProps, STATUS, Step, Placement } from "react-joyride";
import { useParams, useLocation } from "react-router-dom";
import isolatedAppStore from "./store/isolatedAppStore";
import ExpiredSession from "./components/ExpiredSession";
import LoadingPage from "./components/LoadingPage";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import IframeComponent from "./components/IframeComponent";
import { PanelGroup, Panel, PanelResizeHandle } from "react-resizable-panels";
import { Drawer } from "vaul";
import { motion } from "framer-motion";
import DrawerContent from "./components/DrawerContent";
import { notificationManager } from "components/UI/notificationManager";
import axios from "axios";
import { useIsDesktop } from "utils/useIsDesktop";
import publicApiClient from "utils/publicApiClient";
import WarningModal from "components/UI/WarningModal";
import PreBootDemo from "./components/PreBootDemo";

const IsolatedApp: React.FC = observer(() => {
  const { sessionId } = useParams<{ sessionId: string }>();
  const location = useLocation();
  const effectRan = useRef(false);
  const isDesktop = useIsDesktop();
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [hasDismissedWarning, setHasDismissedWarning] = useState(false);

  function isFinishedStatus(status: string): status is "finished" | "skipped" {
    return status === STATUS.FINISHED || status === STATUS.SKIPPED;
  }

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, action } = data;

    if (
      status === STATUS.FINISHED ||
      status === STATUS.SKIPPED ||
      action === "close"
    ) {
      localStorage.setItem("onboardingCompleted", "true");
      runInAction(() => {
        isolatedAppStore.setShowTour(false);
      });
    }
  };

  const tourSteps: Step[] = [
    {
      target: '[data-testid="header-remaining-time"]',
      content:
        "This displays the remaining time before your Synk session is permanently destroyed.",
      placement: "bottom" as Placement,
      disableBeacon: true,
      isFixed: true,
    },
    {
      target: '[data-testid="header-more-time-button"]',
      content:
        "Click here to add extra time to your session. This action can only be performed once per session.",
      placement: "bottom" as Placement,
      disableBeacon: true,
      isFixed: true,
    },
    {
      target: '[data-testid="header-copy-link-button"]',
      content: "Copy the session link to share with others.",
      placement: "bottom" as Placement,
      disableBeacon: true,
      isFixed: true,
    },
    {
      target: '[data-testid="iframe-component"]',
      content:
        "This is your fully anonymous and secure Firefox instance, protected by Synk. No logs are kept.",
      disableBeacon: true,
      isFixed: true,
    },
    ...(isDesktop
      ? [
          {
            target: '[data-testid="header-sidebar-toggle-button"]',
            content:
              "Open or close the left sidebar to access session options.",
            placement: "bottom" as Placement,
            disableBeacon: true,
            isFixed: true,
          },
          {
            target: '[data-testid="header-fullscreen-button"]',
            content: "Enter full-screen mode for an immersive experience.",
            placement: "bottom" as Placement,
            disableBeacon: true,
            isFixed: true,
          },
          {
            target: '[data-testid="sidebar-component"]',
            content:
              "The sidebar allows you to interact with various settings of your session.",
            placement: "right" as Placement,
            disableBeacon: true,
            isFixed: true,
          },
          {
            target: '[data-testid="sidebar-clipboard-button"]',
            content: "Access the clipboard to transfer text to your session.",
            placement: "right" as Placement,
            disableBeacon: true,
            isFixed: true,
          },
        ]
      : [
          {
            target: '[data-testid="iframe-component"]',
            content:
              "You can scroll by dragging with your finger or mouse, and to open the keyboard, double-click on the screen or the input field where you want to type.",
            disableBeacon: true,
            isFixed: true,
          },

          {
            target: '[data-testid="header-settings-button"]',
            content: "Open or close the options menu.",
            placement: "bottom" as Placement,
            disableBeacon: true,
            isFixed: true,
          },
          {
            target: '[data-testid="quick-access-menu"]',
            content:
              "Use this quick access menu to rapidly open or close the options menu.",
            placement: "right" as Placement,
            disableBeacon: true,
            isFixed: true,
          },
        ]),
    {
      target: '[data-testid="sidebar-logout-button"]',
      content: "End your session immediately and delete all logs.",
      placement: "right" as Placement,
      disableBeacon: true,
      isFixed: true,
    },
  ];

  useEffect(() => {
    if (isDesktop) {
      isolatedAppStore.isSidebarOpen = true;
    } else {
      isolatedAppStore.isSidebarOpen = false;
    }
  }, [isDesktop]);

  useEffect(() => {
    if (effectRan.current) return;
    effectRan.current = true;

    const queryParams = new URLSearchParams(location.search);
    const screen_url = queryParams.get("screen_url");

    if (sessionId && screen_url) {
      const decodedScreenUrl = decodeURIComponent(screen_url);

      isolatedAppStore.initialize(decodedScreenUrl);

      publicApiClient
        .get(`/v1/demo/${sessionId}/info`)
        .then((response) => {
          if (response.data && response.data.success) {
            const { runningUntil: newRunningUntil } = response.data.details;

            isolatedAppStore.updateRunningUntil(newRunningUntil);

            runInAction(() => {
              isolatedAppStore.setProgressTo70();
            });

            checkTimeUpdate();
          } else {
            isolatedAppStore.remainingTime = 0;
            isolatedAppStore.isLoading = false;
          }
        })
        .catch((error) => {
          if (
            axios.isAxiosError(error) &&
            (error.response?.status === 429 ||
              error.response?.data?.error?.includes("Rate limit exceeded"))
          ) {
            isolatedAppStore.remainingTime = 0;
            isolatedAppStore.isLoading = false;
          } else {
            console.error("Error fetching demo info:", error);

            isolatedAppStore.remainingTime = 0;
            isolatedAppStore.isLoading = false;
          }
        });

      const checkTimeUpdate = () => {
        if (isolatedAppStore.remainingTime !== null) {
          const timeUntilCheck = (isolatedAppStore.remainingTime - 15) * 1000;
          if (timeUntilCheck > 0) {
            setTimeout(() => {
              publicApiClient
                .get(`/v1/demo/${sessionId}/info`)
                .then((response) => {
                  if (response.data && response.data.success) {
                    const { runningUntil: newRunningUntil } =
                      response.data.details;
                    if (
                      newRunningUntil !==
                      isolatedAppStore.runningUntil?.toISOString()
                    ) {
                      isolatedAppStore.updateRunningUntil(newRunningUntil);
                    }
                  } else {
                    isolatedAppStore.remainingTime = 0;
                    isolatedAppStore.isLoading = false;
                  }
                })
                .catch((error: any) => {
                  if (
                    axios.isAxiosError(error) &&
                    error.response?.status === 429
                  ) {
                    if (
                      isolatedAppStore.remainingTime !== null &&
                      isolatedAppStore.remainingTime <= 0
                    ) {
                      notificationManager.notify({
                        message: "Error",
                        description: error.response.data.error,
                        type: "error",
                      });
                      isolatedAppStore.remainingTime = 0;
                      isolatedAppStore.isLoading = false;
                    }
                  } else {
                    console.error("Error fetching demo info:", error);
                  }
                });
            }, timeUntilCheck);
          }
        }
      };
    } else {
      isolatedAppStore.remainingTime = 0;
      isolatedAppStore.isLoading = false;
    }
  }, [sessionId, location.search]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        isolatedAppStore.isSidebarOpen = true;
      } else {
        isolatedAppStore.isSidebarOpen = false;
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const isOnboardingCompleted = localStorage.getItem("onboardingCompleted");
    if (!isOnboardingCompleted && !isolatedAppStore.isLoading) {
      setTimeout(() => {
        runInAction(() => {
          isolatedAppStore.setShowTour(true);
        });
      }, 300);
    }
  }, [isolatedAppStore.isLoading]);

  useEffect(() => {
    if (!isolatedAppStore.showPreboot && !isolatedAppStore.isLoading) {
      runInAction(() => {
        isolatedAppStore.setShowPreboot(true);
      });
    }
  }, [isolatedAppStore.isLoading]);

  useEffect(() => {
    if (
      !isolatedAppStore.isLoading &&
      (isolatedAppStore.remainingTime === null ||
        isolatedAppStore.remainingTime > 0)
    ) {
      if (!isDesktop && !hasDismissedWarning) {
        setIsWarningModalOpen(true);
      } else {
        setIsWarningModalOpen(false);
      }
    } else {
      setIsWarningModalOpen(false);
    }
  }, [
    isolatedAppStore.isLoading,
    isolatedAppStore.remainingTime,
    isDesktop,
    hasDismissedWarning,
  ]);

  const handleWarningModalClose = () => {
    setIsWarningModalOpen(false);
    setHasDismissedWarning(true);
  };

  return (
    <>
      {isolatedAppStore.remainingTime !== null &&
      isolatedAppStore.remainingTime <= 0 ? (
        <ExpiredSession />
      ) : (
        <>
          <div
            className="h-[100svh] w-screen flex flex-col relative overflow-hidden"
            style={{
              background: "linear-gradient(180deg,#00FFD1,#14467D) 100vw 100vh",
              zIndex: 0,
              filter: `brightness(${isolatedAppStore.brightness})`,
            }}
          >
            {isolatedAppStore.isLoading && (
              <div className="absolute inset-0 z-50">
                <LoadingPage />
              </div>
            )}

            {isolatedAppStore.showPreboot && (
              <div className="absolute inset-0 z-[99999999]">
                <PreBootDemo />
              </div>
            )}

            <div
              className="z-1 absolute inset-0 bg-cover bg-no-repeat animate-fadeInWithBlurDelayed opacity-0"
              style={{
                backgroundImage: 'url("/images/Ellipse1.png")',
                zIndex: 1,
              }}
            ></div>

            <div
              className="z-3 absolute inset-0 bg-customGray/80 backdrop-blur-dock"
              style={{ zIndex: 3 }}
            ></div>
            <Header />

            <div className="relative z-40 pl-1.5 flex flex-1 overflow-hidden min-h-0">
              <Drawer.Root
                open={isolatedAppStore.isDrawerOpen}
                onOpenChange={isolatedAppStore.toggleDrawer}
                direction="left"
              >
                <Drawer.Trigger asChild>
                  <motion.div
                    data-testid="quick-access-menu"
                    className="absolute left-0 top-1/2 transform -translate-y-1/2 h-[80px] w-5 sm:w-4 bg-customGray/80 bg-opacity-50 backdrop-blur-sm z-50 cursor-pointer rounded-full rounded-l-lg flex items-center justify-center"
                  >
                    <div className="w-1 h-12 rounded-full bg-white bg-opacity-80 backdrop-blur-sm"></div>
                  </motion.div>
                </Drawer.Trigger>
                <Drawer.Portal>
                  <Drawer.Overlay className="fixed inset-0 z-40 bg-black bg-opacity-50" />
                  <Drawer.Content className="bg-customGray/60 backdrop-blur-dock flex flex-col rounded-t-[10px] h-full w-[270px] fixed bottom-0 left-0 z-50">
                    <div className="p-4 flex-1 h-full">
                      <DrawerContent />
                    </div>
                  </Drawer.Content>
                </Drawer.Portal>
              </Drawer.Root>

              <PanelGroup
                autoSaveId="conditional"
                direction="horizontal"
                className="flex flex-1"
              >
                {isolatedAppStore.isSidebarOpen && (
                  <>
                    <Panel
                      defaultSize={22}
                      minSize={12}
                      maxSize={30}
                      id="left"
                      order={1}
                      className="h-full relative min-w-[200px]"
                    >
                      <Sidebar />
                    </Panel>
                    <PanelResizeHandle className="h-[98%] w-1 cursor-col-resize data-[resize-handle-active]:bg-white/20 data-[resize-handle-active]:backdrop-blur-custom hover:bg-white/20 hover:backdrop-blur-custom" />
                  </>
                )}
                <Panel id="center" order={2} className="flex-1">
                  <IframeComponent />
                </Panel>
              </PanelGroup>
            </div>
            {!isolatedAppStore.isLoading && !isolatedAppStore.showPreboot && (
              <Joyride
                steps={tourSteps}
                run={isolatedAppStore.showTour}
                continuous
                disableOverlayClose
                spotlightPadding={5}
                scrollToFirstStep
                showSkipButton
                callback={handleJoyrideCallback}
                styles={{
                  options: {
                    zIndex: 10000,
                    primaryColor: "#00FFD1",
                    backgroundColor: "#182C3B",
                    textColor: "#FFFFFF",
                    overlayColor: "rgba(0, 0, 0, 0.7)",
                    arrowColor: "#22dcd1",
                  },

                  buttonClose: {
                    color: "#FFFFFF",
                  },
                  buttonBack: {
                    color: "#FFFFFF",
                  },
                  buttonNext: {
                    backgroundColor: "#22dcd1",
                    color: "#000000",
                  },
                  tooltip: {
                    borderRadius: "10px",
                  },
                }}
                locale={{
                  skip: "Skip and Don't Show Again",
                }}
              />
            )}
            {!isolatedAppStore.isLoading && (
              <WarningModal
                isOpen={isWarningModalOpen}
                onClose={handleWarningModalClose}
                title="Notice"
                message="This application is not optimized for screens of this size. You can continue to use it, but performance may be degraded."
              />
            )}
          </div>
        </>
      )}
    </>
  );
});

export default IsolatedApp;
