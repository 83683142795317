"use client";
import { useEffect } from "react";
import { motion, stagger, useAnimate } from "framer-motion";
import { cn } from "lib/utils";

export const TextGenerateEffect = ({
  words,
  className,
  filter = true,
  duration = 0.5,
}: {
  words: string | (string | JSX.Element)[];
  className?: string;
  filter?: boolean;
  duration?: number;
}) => {
  const [scope, animate] = useAnimate();

  const wordsArray = typeof words === "string" ? words.split(" ") : words;

  useEffect(() => {
    animate(
      "span",
      {
        opacity: 1,
        filter: filter ? "blur(0px)" : "none",
      },
      {
        duration: duration ? duration : 1,
        delay: stagger(0.1),
      }
    );
  }, [scope, filter, duration, animate]);

  const renderWords = () => {
    return (
      <motion.div ref={scope}>
        {wordsArray.map((word, idx) => {
          if (typeof word === "string") {
            const splitted = word.split(" ");
            return splitted.map((w, i) => (
              <motion.span
                key={`${w}-${idx}-${i}`}
                className="text-white opacity-0"
                style={{
                  filter: filter ? "blur(10px)" : "none",
                }}
              >
                {w}{" "}
              </motion.span>
            ));
          } else {
            return (
              <motion.span
                key={`elem-${idx}`}
                className="opacity-0"
                style={{
                  display: "inline-block",
                }}
              >
                {word}{" "}
              </motion.span>
            );
          }
        })}
      </motion.div>
    );
  };

  return (
    <div className={cn("font-bold", className)}>
      <div className="mt-4">
        <div className="text-white text-md sm:text-lg md:text-xl xl:text-2xl leading-snug tracking-wide font-monospace tracking-tight-4 gradient-stroke">
          {renderWords()}
        </div>
      </div>
    </div>
  );
};
