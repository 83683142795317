import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { ShootingStars } from "components/UI/shooting-stars";
import { Spotlight } from "components/UI/Spotlight";
import { useNavigate } from "react-router-dom";
import { PlaceholdersAndVanishInput } from "components/UI/placeholders-and-vanish-input";
import { Particles } from "components/UI/Particles";
import { Tabs } from "components/UI/my_tabs";
import { motion } from "framer-motion";
import { cn } from "lib/utils";
import {
  Card,
  CardBody,
  Image,
  Button,
  CardFooter,
  CardHeader,
  Skeleton,
  ScrollShadow,
} from "@nextui-org/react";
import { Icon } from "@iconify/react";

type Tab = {
  title: string;
  value: string;
  searchUrl: string;
  content?: React.ReactNode;
};

const searchEngines: Tab[] = [
  {
    title: "DuckDuckGo",
    value: "duckduckgo",
    searchUrl: "https://duckduckgo.com/?q=",
    content: null,
  },
  {
    title: "Google",
    value: "google",
    searchUrl: "https://www.google.com/search?q=",
    content: null,
  },
  {
    title: "Qwant",
    value: "qwant",
    searchUrl: "https://www.qwant.com/?q=",
    content: null,
  },
  {
    title: "Searx",
    value: "searx",
    searchUrl: "https://searx.be/search?q=",
    content: null,
  },
  {
    title: "Startpage",
    value: "startpage",
    searchUrl: "https://www.startpage.com/do/search?query=",
    content: null,
  },
];

const SynkSearchEngine: React.FC = observer(() => {
  const navigate = useNavigate();

  const [query, setQuery] = useState("");
  const [selectedEngine, setSelectedEngine] = useState(searchEngines[0]);
  const [userData, setUserData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isCardVisible, setIsCardVisible] = useState(true);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  const placeholders = [
    "What is the current value of Ether?",
    "How does blockchain ensure data security?",
    "What is the current value of Synk?",
    "How to stake cryptocurrencies?",
    "What are the advantages of decentralization?",
  ];

  const handleAnimationComplete = (query: string) => {
    window.open(
      `${selectedEngine.searchUrl}${encodeURIComponent(query)}`,
      "_blank"
    );
    setQuery("");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://ipapi.co/json/");

        if (!response.ok) {
          console.error(
            `Error fetching user data: ${response.status} ${response.statusText}`
          );
          setIsCardVisible(false);
        } else {
          const data = await response.json();

          const browser = getBrowserInfo();

          const combinedData = {
            ...data,
            browser,
          };

          setUserData(combinedData);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        setIsCardVisible(false);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const getBrowserInfo = () => {
    const userAgent = navigator.userAgent;
    let browserName = "Unknown";
    if (userAgent.indexOf("Firefox") > -1) {
      browserName = "Mozilla Firefox";
    } else if (
      userAgent.indexOf("Opera") > -1 ||
      userAgent.indexOf("OPR") > -1
    ) {
      browserName = "Opera";
    } else if (userAgent.indexOf("Trident") > -1) {
      browserName = "Microsoft Internet Explorer";
    } else if (userAgent.indexOf("Edge") > -1) {
      browserName = "Microsoft Edge";
    } else if (userAgent.indexOf("Chrome") > -1) {
      browserName = "Google Chrome";
    } else if (userAgent.indexOf("Safari") > -1) {
      browserName = "Apple Safari";
    }
    return browserName;
  };

  return (
    <div className="h-screen w-full rounded-md flex flex-col items-center justify-center bg-black/[0.96] antialiased bg-grid-white/[0.02] relative overflow-hidden">
      <div className="absolute inset-0 bg-customGray animate-fadeOut z-0 pointer-events-none"></div>
      <div
        className="absolute inset-0 bg-cover bg-no-repeat animate-fadeInWithBlurDelayed opacity-0"
        style={{
          backgroundImage: 'url("/images/Ellipse1.png")',
          zIndex: 0,
        }}
      ></div>

      <Particles
        className="absolute inset-0"
        quantity={100}
        ease={80}
        color={"#ffffff"}
        refresh
      />

      <Spotlight
        className="-top-40 left-0 md:left-60 md:-top-20"
        fill="#22dcd1"
      />

      <div className="z-50 flex flex-col w-screen justify-center items-center px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.5 }}
          className="flex flex-col items-center w-full"
        >
          <p className="text-center uppercase text-5xl md:text-8xl lg:text-[6rem] font-bold bg-clip-text text-transparent bg-gradient-to-b from-synkColor/20 to-synkColor/60 inset-x-0">
            Synk Search
          </p>
          <div className="mt-[-10px] md:mt-[-22px] lg:mt-[-22px] w-[95%] max-w-[100%] sm:max-w-[80%] md:w-full">
            <PlaceholdersAndVanishInput
              value={query}
              placeholders={placeholders}
              onChange={handleChange}
              onSubmit={handleSubmit}
              onAnimationComplete={handleAnimationComplete}
            />
          </div>
        </motion.div>

        <ScrollShadow
          orientation="horizontal"
          className="max-w-[90%] w-auto flex sm:justify-center"
          hideScrollBar
        >
          <Tabs
            tabs={searchEngines}
            activeTab={selectedEngine}
            onTabChange={(tab) => {
              setSelectedEngine(tab);
            }}
            containerClassName="mt-3 sm:justify-center"
            activeTabClassName="!bg-zinc-700/60 border-b-2 !backdrop-blur-custom border-synkColor/60 gradient-stroke !text-black"
          />
        </ScrollShadow>

        <div className="w-full flex justify-center">
          {isCardVisible && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
              className="mt-12 w-full max-w-[60%] md:max-w-[520px]"
            >
              <Card className="w-full bg-customGray/30 backdrop-blur-custom relative !shadow-custom">
                <div className="absolute inset-0 h-full w-full bg-[linear-gradient(to_right,#22dcd116_1px,transparent_1px),linear-gradient(to_bottom,#22dcd116_1px,transparent_1px)] bg-[size:32px_32px]"></div>

                <Button
                  isIconOnly
                  className="absolute right-2 top-3 z-20 !bg-customGray/80 backdrop-blur-dock"
                  radius="full"
                  size="sm"
                  variant="light"
                  onClick={() => setIsCardVisible(false)}
                >
                  <Icon
                    className="text-default-600"
                    icon="iconamoon:close-thin"
                    width={24}
                  />
                </Button>

                <CardBody className="flex flex-row flex-wrap p-0 md:flex-nowrap">
                  {isLoading ? (
                    <Skeleton
                      isLoaded={true}
                      className="h-auto w-full flex-none md:w-48 rounded-large"
                    >
                      <div className="h-[140px] sm:h-[160px] w-full rounded-large bg-gray-300 animate-pulse"></div>
                    </Skeleton>
                  ) : (
                    <div className="h-auto w-full flex-none md:w-48">
                      <iframe
                        width="100%"
                        className="rounded-large h-[140px] sm:h-[160px]"
                        height="auto"
                        src={`https://www.openstreetmap.org/export/embed.html?bbox=${
                          userData.longitude - 0.05
                        }%2C${userData.latitude - 0.05}%2C${
                          userData.longitude + 0.05
                        }%2C${userData.latitude + 0.05}&layer=mapnik&marker=${
                          userData.latitude
                        }%2C${userData.longitude}`}
                        style={{ border: 0 }}
                        allowFullScreen
                        loading="lazy"
                      ></iframe>
                    </div>
                  )}

                  <div className="p-4 px-3 sm:p-4 sm:px-6 flex flex-col justify-between gap-4 w-full">
                    <div>
                      {isLoading ? (
                        <Skeleton className="w-3/5 h-6 rounded-md">
                          <div className="w-3/5 h-6 rounded-md bg-gray-300 animate-pulse"></div>
                        </Skeleton>
                      ) : (
                        <p className="hidden sm:flex text-lg font-bold text-white gradient-stroke">
                          Your connection
                        </p>
                      )}
                    </div>
                    <div className="font-normal text-md text-neutral-200">
                      {isLoading ? (
                        <>
                          <Skeleton className="w-full h-4 rounded-md mb-2">
                            Your IP address is blablabla located in blablabla
                            and you are using blablabla
                          </Skeleton>
                          <Skeleton className="w-full h-4 rounded-md mb-2">
                            Your IP address is blablabla located in blablabla
                            and you are using blablabla
                          </Skeleton>
                        </>
                      ) : (
                        <p>
                          Your IP address is{" "}
                          <Highlight>{userData.ip}</Highlight>, located in{" "}
                          {userData.city},
                          <Highlight>{userData.country_name}</Highlight>, and
                          you are using{" "}
                          <Highlight>{userData.browser}</Highlight>.
                        </p>
                      )}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </motion.div>
          )}
        </div>
      </div>

      <ShootingStars />
    </div>
  );
});

export default SynkSearchEngine;

export const Highlight = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <span
      className={cn(
        "font-bold bg-emerald-700/[0.2] text-emerald-500 px-1 py-0.5",
        className
      )}
    >
      {children}
    </span>
  );
};
