import React, { useState, useEffect } from "react";
import { MultiStepLoader as Loader } from "components/UI/multi-step-loader";
import { IconSquareRoundedX } from "@tabler/icons-react";
import { useNavigate, useLocation } from "react-router-dom";
import { ROUTES } from "routes/routes";
import axios from "axios";
import publicApiClient from "utils/publicApiClient";
import { v4 as uuidv4 } from "uuid";

const loadingStates = [
  { text: "Initializing Synk session..." },
  { text: "Creating a secure container..." },
  { text: "Launching Firefox securely..." },
  { text: "Setting up a secure connection..." },
  { text: "Redirecting..." },
];

export function InstanceStarter() {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const startDemo = async () => {
      try {
        const params = new URLSearchParams(location.search);
        const openUrl = params.get("open_url");

        let apiEndpoint = "/v1/demo/start";
        if (openUrl) {
          const encodedOpenUrl = encodeURIComponent(openUrl);
          apiEndpoint += `?open_url=${encodedOpenUrl}`;
        }

        const response = await publicApiClient.post(apiEndpoint);
        if (response.data && response.data.success) {
          const { screen_url, appInstanceId } = response.data.details;
          const sessionId = appInstanceId || uuidv4();
          const encodedScreenUrl = encodeURIComponent(screen_url);

          const newUrl = `${ROUTES.ISOLATEDAPP.replace(
            ":sessionId",
            sessionId
          )}?screen_url=${encodedScreenUrl}`;

          navigate(newUrl);
        } else {
          navigate(ROUTES.ERROR_PAGE);
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 429) {
            navigate(ROUTES.RATE_LIMIT);
          } else {
            navigate(ROUTES.ERROR_PAGE);
          }
        } else {
          navigate(ROUTES.ERROR_PAGE);
        }
      }
    };

    startDemo();
  }, [navigate, location.search]);

  return (
    <div className="h-[100svh] w-screen flex items-center justify-center">
      <div className="absolute inset-0 bg-customGray animate-fadeOut z-0 pointer-events-none"></div>
      <div
        className="absolute inset-0 bg-cover bg-no-repeat animate-fadeInWithBlurDelayed opacity-0"
        style={{
          backgroundImage: 'url("/images/Ellipse1.png")',
          zIndex: 0,
        }}
      ></div>

      <Loader
        loop={false}
        loadingStates={loadingStates}
        loading={loading}
        duration={650}
      />

      {loading && (
        <button
          className="fixed top-4 right-4 text-black dark:text-white z-[120]"
          onClick={() => setLoading(false)}
        >
          <IconSquareRoundedX className="h-10 w-10" />
        </button>
      )}
    </div>
  );
}

export default InstanceStarter;
