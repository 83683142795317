"use client";
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { cn } from "lib/utils";
import { Spotlight } from "./Spotlight";
import { ShootingStars } from "./shooting-stars";

export const LampContainer = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const initialWidth = isMobile ? "8rem" : "15rem";
  const whileInViewWidth = isMobile ? "16rem" : "30rem";

  return (
    <div
      className={cn(
        "relative flex min-h-screen flex-col items-center justify-center overflow-hidden bg-black w-full rounded-md z-0",
        className
      )}
    >
      <div className="w-full pl-[25px] pt-[25px]">
        <div
          className={`relative z-10 text-left text-white font-logo text-[1.8rem] leading-[100%] tracking-[-0.08em] gradient-stroke`}
        >
          Synk
        </div>
      </div>

      {/* <Spotlight
        className="-top-40 left-0 md:left-[8rem] md:top-0"
        fill="#C20E4D"
      /> */}
      <div className="absolute inset-0 bg-[#C20E4D]/5 z-[8]"></div>

      <ShootingStars starColor="#C20E4D" trailColor="#F31260" />
      <div className="absolute inset-0 h-full w-full z-[5] bg-[linear-gradient(to_right,#F3126030_1px,transparent_1px),linear-gradient(to_bottom,#F3126030_1px,transparent_1px)] bg-[size:64px_64px]"></div>

      <div className="bg-gradient-to-t inset-x-0 z-30 bottom-0 bg-[#C20E4D]/5 h-full absolute [mask-image:radial-gradient(900px_at_center,_transparent_50%,_white)]"></div>

      <div className="relative flex w-full flex-1 scale-y-125 items-center mt-[-100%] sm:mt-[-40%] md:mt-[-45%] lg:mt-[-35%] xl:mt-[-20%] justify-center isolate z-0 ">
        <motion.div
          initial={{ opacity: 0.5, width: initialWidth }}
          whileInView={{ opacity: 1, width: whileInViewWidth }}
          transition={{
            delay: 0.3,
            duration: 0.8,
            ease: "easeInOut",
          }}
          style={{
            backgroundImage: `conic-gradient(var(--conic-position), var(--tw-gradient-stops))`,
          }}
          className="absolute inset-auto right-1/2 h-56 overflow-visible bg-gradient-conic from-red-600 via-transparent to-transparent text-white [--conic-position:from_70deg_at_center_top]"
        >
          <div className="absolute w-full left-0 bg-black h-40 bottom-0 z-20 [mask-image:linear-gradient(to_top,white,transparent)]" />
          <div className="absolute w-80 h-full left-0 bg-black bottom-0 z-20 [mask-image:linear-gradient(to_right,white,transparent)]" />
        </motion.div>
        <motion.div
          initial={{ opacity: 0.5, width: initialWidth }}
          whileInView={{ opacity: 1, width: whileInViewWidth }}
          transition={{
            delay: 0.3,
            duration: 0.8,
            ease: "easeInOut",
          }}
          style={{
            backgroundImage: `conic-gradient(var(--conic-position), var(--tw-gradient-stops))`,
          }}
          className="absolute inset-auto left-1/2 h-56 overflow-visible bg-gradient-conic from-transparent via-transparent to-red-600 text-white [--conic-position:from_290deg_at_center_top]"
        >
          <div className="absolute w-40 h-full right-0 bg-black bottom-0 z-20 [mask-image:linear-gradient(to_left,white,transparent)]" />
          <div className="absolute w-full right-0 bg-black h-40 bottom-0 z-20 [mask-image:linear-gradient(to_top,white,transparent)]" />
        </motion.div>
        <div className="absolute top-1/2 h-48 w-full translate-y-12 scale-x-150 bg-black/40 blur-2xl"></div>
        <div className="absolute top-1/2 z-50 h-48 w-full bg-transparent opacity-10 backdrop-blur-md"></div>
        <div className="absolute inset-auto z-50 h-36 w-[28rem] -translate-y-1/2 rounded-full bg-red-600 opacity-50 blur-3xl"></div>
        <motion.div
          initial={{ width: isMobile ? "8rem" : "16rem" }}
          whileInView={{ width: isMobile ? "14rem" : "24rem" }}
          transition={{
            delay: 0.3,
            duration: 0.8,
            ease: "easeInOut",
          }}
          className="absolute inset-auto z-30 h-36 -translate-y-[6rem] rounded-full bg-red-600 blur-2xl"
        ></motion.div>
        <motion.div
          initial={{ width: isMobile ? "10rem" : "15rem" }}
          whileInView={{ width: isMobile ? "20rem" : "30rem" }}
          transition={{
            delay: 0.3,
            duration: 0.8,
            ease: "easeInOut",
          }}
          className="absolute inset-auto z-50 h-0.5 -translate-y-[7rem] bg-red-400 "
        ></motion.div>

        <div className="absolute inset-auto z-40 h-44 w-full -translate-y-[12.5rem] bg-black/60 "></div>
      </div>

      <div className="absolute inset-auto w-full h-[100svh] flex items-center">
        <div className="relative z-50 flex w-full items-center flex-col px-5">
          {children}
        </div>
      </div>
    </div>
  );
};
