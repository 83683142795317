// components/UI/my_tabs.tsx

import React, { useState } from "react";
import { motion } from "framer-motion";
import classNames from "classnames";

type Tab = {
  title: string;
  value: string;
  searchUrl: string;
  content?: React.ReactNode;
};

export const Tabs = ({
  tabs,
  activeTab,
  onTabChange,
  containerClassName,
  activeTabClassName,
  tabClassName,
}: {
  tabs: Tab[];
  activeTab: Tab;
  onTabChange: (tab: Tab) => void;
  containerClassName?: string;
  activeTabClassName?: string;
  tabClassName?: string;
}) => {
  const [hovering, setHovering] = useState(false);

  return (
    <div
      className={classNames(
        "flex flex-row items-center justify-start relative max-w-full w-full",
        containerClassName
      )}
    >
      {tabs.map((tab) => (
        <button
          key={tab.title}
          onClick={() => onTabChange(tab)}
          onMouseEnter={() => setHovering(true)}
          onMouseLeave={() => setHovering(false)}
          className={classNames(
            "relative px-4 py-2 rounded-full",
            tabClassName
          )}
          style={{
            transformStyle: "preserve-3d",
          }}
        >
          {activeTab.value === tab.value && (
            <motion.div
              layoutId="clickedbutton"
              transition={{ type: "spring", bounce: 0.3, duration: 0.6 }}
              className={classNames(
                "absolute inset-0 bg-zinc-800 rounded-full text-white",
                activeTabClassName
              )}
            />
          )}

          <span className="relative block">{tab.title}</span>
        </button>
      ))}
    </div>
  );
};
