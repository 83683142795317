import { makeAutoObservable, runInAction } from "mobx";
import apiClient from "../utils/apiClient";
import userStore from "./UserStore";
import i18n from "../i18n";
import BackgroundSelector from "components/BackgroundSelector/BackgroundSelector";
import Terminal from "locaLapps/Terminal/Terminal";
import Settings from "locaLapps/Settings/Settings";
import MarioKart from "locaLapps/MarioKart/MarioKart";
import Doom from "locaLapps/Doom/Doom";
import { notificationManager } from "components/UI/notificationManager";
import { v4 as uuidv4 } from "uuid";

import Test from "pages/Test/Test";

interface AppInstance {
  status: string;
  screen_url: string | null;
  appInstanceId: string | null;
  vpn?: string;
}

interface App {
  appName: string;
  appLogoUrl: string;
  appInstances: AppInstance[];
}

interface AvailableApp {
  appName: string;
  appLogoUrl: string;
  component?: React.ComponentType;
  noScrollArea?: boolean;
}

export interface RunningAppInstance {
  appInstanceId: string;
  appName: string;
  appLogoUrl: string;
  screenUrl: string | null;
  minimized: boolean;
  position: { x: number; y: number };
  windowSize: { width: number; height: number };
  iframeSize: { width: number; height: number };
  zIndex: number;
  isLoading: boolean;
  isMaximized: boolean;
  customWindowSize?: { width: number; height: number };
  customPosition?: { x: number; y: number };
  isMinimizable?: boolean;
  component?: React.ComponentType;
  defaultWindowSize?: { width: number; height: number };
  defaultPosition?: { x: number; y: number };
  vpn?: string;
  props?: any;
  noScrollArea?: boolean;
}

class AppStore {
  remoteAvailableApps: AvailableApp[] = [];
  runningApps: Map<string, RunningAppInstance> = new Map();
  isLoading: boolean = false;
  error: string = "";
  isSettingsOpen: boolean = false;
  highestZIndex: number = 100;
  delayBeforeDisplay: number = 3000;

  localApps: AvailableApp[] = [
    {
      appName: "Synk Bot",
      appLogoUrl: "/images/app/synkbot.svg",
      component: Test,
    },
    {
      appName: "Terminal",
      appLogoUrl: "/images/app/terminal.svg",
      component: Terminal,
    },
    {
      appName: "Settings",
      appLogoUrl: "/images/app/settings.svg",
      component: Settings,
    },
    {
      appName: "Doom",
      appLogoUrl: "/images/app/doom.png",
      component: Doom,
      noScrollArea: true,
    },
    {
      appName: "Mario Kart",
      appLogoUrl: "/images/app/mario.png",
      component: MarioKart,
      noScrollArea: true,
    },
  ];

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  transformScreenUrl(screenUrl: string): string {
    return screenUrl.replace(/^wss:\/\//i, "https://");
  }

  get availableApps(): AvailableApp[] {
    return [...this.localApps, ...this.remoteAvailableApps];
  }

  async handleVPNChange(vpnStatus: string) {
    this.runningApps.forEach((appInstance) => {
      appInstance.isLoading = true;
    });

    try {
      await new Promise((resolve) => setTimeout(resolve, 2500));

      this.runningApps.forEach((appInstance) => {
        appInstance.isLoading = false;
        appInstance.vpn = vpnStatus;
      });
    } catch (error) {
      this.handleError(error, "error_changing_vpn", {
        vpnName: vpnStatus,
      });

      this.runningApps.forEach((appInstance) => {
        appInstance.isLoading = false;
      });
    }
  }

  setCustomSize(
    appInstanceId: string,
    size: { width: number; height: number }
  ) {
    const appInstance = this.runningApps.get(appInstanceId);
    if (appInstance) {
      appInstance.customWindowSize = size;
    }
  }

  setCustomPosition(appInstanceId: string, position: { x: number; y: number }) {
    const appInstance = this.runningApps.get(appInstanceId);
    if (appInstance) {
      appInstance.customPosition = position;
    }
  }

  setIsMaximized(appInstanceId: string, isMaximized: boolean) {
    const appInstance = this.runningApps.get(appInstanceId);
    if (appInstance) {
      appInstance.isMaximized = isMaximized;
    }
  }

  updateAppPositionAndSize(
    appInstanceId: string,
    position: { x: number; y: number },
    windowSize: { width: number; height: number },
    iframeSize: { width: number; height: number }
  ) {
    const appInstance = this.runningApps.get(appInstanceId);
    if (appInstance) {
      appInstance.position = position;
      appInstance.windowSize = windowSize;
      appInstance.iframeSize = iframeSize;
    }
  }

  bringAppToFront(appInstanceId: string) {
    const appInstance = this.runningApps.get(appInstanceId);
    if (appInstance) {
      this.highestZIndex += 1;
      appInstance.zIndex = this.highestZIndex;
    }
  }

  minimizeAppInstance(appInstanceId: string) {
    const appInstance = this.runningApps.get(appInstanceId);
    if (appInstance) {
      appInstance.minimized = true;
    }
  }

  restoreAppInstance(appInstanceId: string) {
    const appInstance = this.runningApps.get(appInstanceId);
    if (appInstance) {
      appInstance.minimized = false;
      this.bringAppToFront(appInstanceId);
    }
  }

  async fetchApps() {
    this.isLoading = true;
    this.error = "";

    try {
      const response = await apiClient.get("/v1/apps");
      const apps = response.data.apps as App[];

      const uniqueAppsMap = new Map<string, AvailableApp>();
      for (const app of apps) {
        if (!uniqueAppsMap.has(app.appName)) {
          uniqueAppsMap.set(app.appName, {
            appName: app.appName,
            appLogoUrl: app.appLogoUrl,
          });
        }
      }

      runInAction(() => {
        this.remoteAvailableApps = Array.from(uniqueAppsMap.values());

        const newRunningApps = new Map<string, RunningAppInstance>();
        apps.forEach((app) => {
          app.appInstances.forEach((instance: AppInstance) => {
            if (
              instance.status === "Running" &&
              instance.screen_url &&
              instance.appInstanceId
            ) {
              const appInstanceId = instance.appInstanceId;
              const existingInstance = this.runningApps.get(appInstanceId);
              const minimized = existingInstance
                ? existingInstance.minimized
                : false;
              const position = existingInstance
                ? existingInstance.position
                : { x: 100, y: 100 };
              const windowSize = existingInstance
                ? existingInstance.windowSize
                : { width: 600, height: 400 };
              const iframeSize = existingInstance
                ? existingInstance.iframeSize
                : { width: 600, height: 360 };
              const zIndex = existingInstance
                ? existingInstance.zIndex
                : ++this.highestZIndex;
              const isLoading = existingInstance
                ? existingInstance.isLoading
                : false;

              newRunningApps.set(appInstanceId, {
                appInstanceId,
                appName: app.appName,
                appLogoUrl: app.appLogoUrl,
                screenUrl: this.transformScreenUrl(instance.screen_url),
                minimized,
                position,
                windowSize,
                iframeSize,
                zIndex,
                isLoading,
                isMaximized: existingInstance?.isMaximized || false,
                customWindowSize: existingInstance?.customWindowSize,
                customPosition: existingInstance?.customPosition,
                vpn: instance.vpn || "none",
              });
            }
          });
        });

        this.runningApps = newRunningApps;
      });
    } catch (error: any) {
      this.handleError(error, "error_loading_apps");
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  async launchApp(appName: string, props?: any) {
    const app = this.availableApps.find((app) => app.appName === appName);

    if (app?.component) {
      const appInstanceId = this.generateUniqueId();
      const zIndex = ++this.highestZIndex;
      const windowSize = { width: 800, height: 600 };
      const position = { x: 100, y: 100 };

      const newInstance: RunningAppInstance = {
        appInstanceId,
        appName,
        appLogoUrl: app.appLogoUrl,
        screenUrl: null,
        minimized: false,
        position,
        windowSize,
        iframeSize: { width: 800, height: 560 },
        zIndex,
        isLoading: false,
        isMaximized: false,
        component: app.component,
        defaultWindowSize: windowSize,
        defaultPosition: position,
        props,
        noScrollArea: app.noScrollArea,
      };
      this.runningApps.set(appInstanceId, newInstance);
    } else {
      try {
        const response = await apiClient.post(`/v1/apps/${appName}/start`);
        const { success, details } = response.data;

        if (success) {
          const { appInstanceId, screen_url } = details;

          const zIndex = ++this.highestZIndex;
          const windowSize = { width: 800, height: 600 };
          const iframeSize = { width: 800, height: 560 };
          const newInstance: RunningAppInstance = {
            appInstanceId,
            appName,
            appLogoUrl: this.getAppLogoUrl(appName),
            screenUrl: null,
            minimized: false,
            position: { x: 100, y: 100 },
            windowSize,
            iframeSize,
            zIndex,
            isLoading: true,
            isMaximized: false,
          };
          this.runningApps.set(appInstanceId, newInstance);

          if (appInstanceId && screen_url) {
            this.pollAppInstanceStatus(appInstanceId, screen_url);
          } else {
            this.handleError(null, "error_launching_app", { appName });
          }
        } else {
          this.handleError(null, "error_launching_app", { appName });
        }
      } catch (error: any) {
        this.handleError(error, "error_launching_app", { appName });
      }
    }
  }

  async pollAppInstanceStatus(appInstanceId: string, screen_url: string) {
    try {
      const checkStatus = async () => {
        try {
          const response = await apiClient.get("/v1/apps");
          const apps = response.data.apps as App[];

          let found = false;
          let runningInstance: AppInstance | null = null;

          for (const app of apps) {
            for (const instance of app.appInstances) {
              if (instance.appInstanceId === appInstanceId) {
                if (instance.status === "Running" && instance.screen_url) {
                  runningInstance = instance;
                }
                found = true;
                break;
              }
            }
            if (found) break;
          }

          if (runningInstance) {
            await new Promise((resolve) =>
              setTimeout(resolve, this.delayBeforeDisplay)
            );

            runInAction(() => {
              const appInstance = this.runningApps.get(appInstanceId);
              if (appInstance) {
                appInstance.screenUrl = this.transformScreenUrl(
                  runningInstance!.screen_url!
                );
                appInstance.isLoading = false;
                appInstance.vpn = runningInstance!.vpn || "none";
              }
            });
          } else if (found) {
            setTimeout(checkStatus, 1000);
          } else {
            console.warn(`App instance ${appInstanceId} not found.`);
          }
        } catch (error: any) {
          this.handleError(error, "error_loading_apps");
        }
      };

      checkStatus();
    } catch (error: any) {
      this.handleError(
        error,
        `Erreur lors de la vérification du statut de l'application.`
      );
    }
  }

  async closeAppInstance(appInstanceId: string) {
    const appInstance = this.runningApps.get(appInstanceId);
    if (appInstance) {
      this.runningApps.delete(appInstanceId);

      if (appInstance.component) {
        return;
      }

      try {
        const response = await apiClient.post(
          `/v1/apps/${appInstance.appInstanceId}/stop`
        );
        const { success } = response.data;
        if (!success) {
          this.runningApps.set(appInstanceId, appInstance);
          this.handleError(null, "error_closing_specific_app", {
            appName: appInstance.appName,
          });
        }
      } catch (error: any) {
        this.runningApps.set(appInstanceId, appInstance);
        this.handleError(error, "error_closing_specific_app", {
          appName: appInstance.appName,
        });
      }
    }
  }

  getAppLogoUrl(appName: string): string {
    const app = this.availableApps.find((app) => app.appName === appName);
    return app ? app.appLogoUrl : "";
  }

  focusOrLaunchApp(appName: string, props?: any) {
    const runningInstances = Array.from(this.runningApps.values()).filter(
      (instance) => instance.appName === appName
    );

    if (runningInstances.length > 0) {
      const instance = runningInstances[0];
      if (props) {
        instance.props = props;
      }
      this.restoreAppInstance(instance.appInstanceId);
      this.bringAppToFront(instance.appInstanceId);
    } else {
      this.launchApp(appName, props);
    }
  }

  generateUniqueId(): string {
    return `local-${uuidv4()}`;
  }

  handleError(error: any, defaultMessageKey: string, params?: any) {
    const errorMessage =
      userStore.debugMode && error?.message
        ? error.message
        : i18n.t(defaultMessageKey, params);
    notificationManager.notify({
      message: i18n.t("error"),
      description: errorMessage,
      duration: 30,
    });
  }
}

const appStore = new AppStore();
export default appStore;
