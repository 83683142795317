import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import "./assets/styles/index.css";
import "./i18n";
import Auth0ProviderWithHistory from "auth/Auth0ProviderWithHistory";
import { NextUIProvider } from "@nextui-org/react";
import { theme } from "theme/theme";
import { ConfigProvider } from "antd";
import { NotificationProvider } from "components/UI/NotificationProvider";
import { ReleaseProvider } from "components/ReleaseContext/ReleaseContext";
import AppRoutes from "routes/AppRoutes";
import { ChakraProvider, defaultSystem } from "@chakra-ui/react";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <ConfigProvider theme={theme}>
    <NotificationProvider>
      <NextUIProvider>
        <ChakraProvider value={defaultSystem}>
          <main className="dark text-foreground bg-background">
            <Auth0ProviderWithHistory>
              <ReleaseProvider>
                <Router>
                  <AppRoutes />
                </Router>
              </ReleaseProvider>
            </Auth0ProviderWithHistory>
          </main>
        </ChakraProvider>
      </NextUIProvider>
    </NotificationProvider>
  </ConfigProvider>
);
