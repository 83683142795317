import React from "react";
import { observer } from "mobx-react-lite";
import { TextGenerateEffect } from "components/UI/text-generate-effect";
import { Button } from "components/UI/Button";
import isolatedAppStore from "../store/isolatedAppStore";
import { useTranslation } from "react-i18next";

const message = [
  'This system is an initial release provided "as is" with no warranties or guarantees. Please use it with caution, as it may contain bugs, errors, or limitations. The primary goal of this release is to gather feedback and identify any issues to enhance the platform further. We appreciate your support and encourage you to report bugs or share feedback to help us improve. For additional details, please refer to the ',
  <a
    key="tos-link"
    href="https://docs.synk.ws"
    className="underline"
    target="_blank"
    rel="noopener noreferrer"
  >
    Terms of Service
  </a>,
  ". Synk team.",
];

const PreBootDemo: React.FC = observer(() => {
  const { t } = useTranslation();

  const handleContinue = () => {
    isolatedAppStore.setShowPreboot(false);
  };

  return (
    <div className="relative flex flex-col items-center justify-center min-h-[100svh] bg-black text-white px-4">
      <div className="absolute inset-0 bg-black animate-fadeOut z-0 pointer-events-none"></div>

      <div
        className="absolute inset-0 bg-cover bg-no-repeat animate-fadeInWithBlurDelayed opacity-0"
        style={{
          backgroundImage: 'url("/images/Ellipse1.png")',
          zIndex: 0,
        }}
      ></div>

      <div className="relative z-10 max-w-xl sm:max-w-[850px] text-center p-4 flex flex-col gap-5">
        <TextGenerateEffect words={message} />

        <div className="w-full flex justify-center">
          <Button
            size="default"
            variant="login"
            onClick={handleContinue}
            className="mt-4"
          >
            Continue to App
          </Button>
        </div>
      </div>
    </div>
  );
});

export default PreBootDemo;
