import { LampContainer } from "components/UI/lamp";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "routes/routes";

export function DNSOverridePage() {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate(ROUTES.SEARCHENGINE);
  };

  return (
    <LampContainer>
      <motion.h1
        initial={{ opacity: 0.5, y: 100 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{
          delay: 0.3,
          duration: 0.8,
          ease: "easeInOut",
        }}
        className="text-4xl md:text-5xl lg:text-7xl uppercase font-bold text-center bg-clip-text text-transparent bg-gradient-to-b from-[#C20E4D] to-red-600 py-1 max-w-[85%] md:max-w-xl lg:max-w-[95%] mt-[5%]"
      >
        Oops! This corner is off-limits.
        <br /> Let’s keep it safe and sound.
      </motion.h1>
      <motion.p
        initial={{ opacity: 0.5, y: 100 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{
          delay: 0.3,
          duration: 0.8,
          ease: "easeInOut",
        }}
        className="mt-4 font-normal text-sm md:text-base lg:text-lg text-neutral-300 max-w-[85%] md:max-w-lg lg:max-w-[95%] mx-auto text-center"
      >
        <strong>DNS_OVERRIDE:</strong> For your safety and the platform’s
        integrity, access to this resource has been restricted.
      </motion.p>

      <motion.button
        initial={{ opacity: 0.5, y: 100 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{
          delay: 0.3,
          duration: 0.8,
          ease: "easeInOut",
        }}
        onClick={handleRedirect}
        className="mt-8 px-4 py-2 rounded-xl border border-neutral-600 text-black bg-white hover:bg-gray-100"
      >
        Return to Safety
      </motion.button>
    </LampContainer>
  );
}
