import { observer } from "mobx-react-lite";
import MessageGenericPage from "components/MessageGenericPage/MessageGenericPage";

const HitLimit: React.FC = observer(() => {
  return (
    <MessageGenericPage
      title={
        <>
          Too Late! <br /> OSynk Has Hit Its Limit.
        </>
      }
      message="These past few days, OSynk has been working overtime! It seems like everyone’s been syncing up, and now OSynk needs a little break to recharge its batteries. Hang tight, and check back soon for more amazing surprises!"
      buttonText="Back to Synk"
    />
  );
});

export default HitLimit;
