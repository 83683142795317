import React from "react";
import { cn } from "lib/utils";
import WindowPreview from "components/Window/WindowPreview";
import { RunningAppInstance } from "stores/AppStore";
import AuthIframe from "pages/SynkOSv2/components/AuthIframe/AuthIframe";

type LinkPreviewProps = {
  children?: React.ReactNode;
  appInstance: RunningAppInstance;
  className?: string;
};

export const LinkPreview: React.FC<LinkPreviewProps> = ({
  children,
  appInstance,
  className,
}) => {
  const scale = 0.25;

  const containerWidth = appInstance.windowSize.width * scale;
  const containerHeight = appInstance.windowSize.height * scale;

  return (
    <div
      className={cn(
        "relative rounded-lg overflow-hidden box-border flex items-center justify-center",
        className
      )}
      style={{ width: "100%", height: "100%" }}
    >
      <div
        className="transform origin-center flex items-center justify-center"
        style={{
          height: containerHeight,
          transform: `scale(${scale})`,
          pointerEvents: "none",
        }}
      >
        <WindowPreview
          title={appInstance.appName}
          windowSize={{
            width: appInstance.windowSize.width,
            height: appInstance.windowSize.height,
          }}
          isLoading={appInstance.isLoading}
          appLogoUrl={appInstance.appLogoUrl}
          component={appInstance.component}
        >
          {appInstance.component ? (
            <div className="w-full h-full">
              {React.createElement(appInstance.component)}
            </div>
          ) : appInstance.screenUrl ? (
            <AuthIframe
              src={appInstance.screenUrl}
              title={appInstance.appName}
              className="w-full h-full border-none rounded-custom"
              sandbox="allow-scripts allow-same-origin allow-popups"
              allow="camera; microphone; fullscreen"
            />
          ) : (
            <div className="w-full h-full bg-gray-200 animate-pulse rounded-custom"></div>
          )}
        </WindowPreview>
      </div>
      {children}
    </div>
  );
};

export default LinkPreview;
