import { Image } from "@nextui-org/react";
import { Card, Carousel } from "components/UI/apple-cards-carousel";
import { ShootingStars } from "components/UI/shooting-stars";
import { StarsBackground } from "components/UI/stars-background";
import { Spotlight } from "components/UI/Spotlight";
import { ROUTES } from "routes/routes";

export function HomePage() {
  const data = [
    {
      category: "Powered by Synk",
      title: "[NEW] Launch Firefox",
      src: "/images/cards/FX.png",
      content: <DummyContent />,
      cardAction: {
        url: ROUTES.START_FX,
        openInNewTab: true,
      },
      buttonProps: {
        type: "pulsating" as "pulsating",
        text: "Start Firefox with Synk power",
        url: ROUTES.START_FX,
        openInNewTab: true,
      },
    },
    {
      category: "Access SynkOS",
      title: "Synk dApp",
      src: "/images/cards/synkdapp.png",
      content: <DummyContent />,
      cardAction: {
        url: ROUTES.OS,
        openInNewTab: true,
      },
      buttonProps: {
        type: "regular" as "regular",
        text: "Access Synk dApp",
        url: ROUTES.OS,
        openInNewTab: true,
      },
    },
    {
      category: "X",
      title: "Follow Us",
      src: "/images/cards/X.png",

      cardAction: {
        url: "https://x.com/synk_ws",
        openInNewTab: true,
      },
      content: <DummyContent />,
      buttonProps: {
        type: "regular" as "regular",
        text: "Follow us on X",
        url: "https://x.com/synk_ws",
        openInNewTab: true,
      },
    },
    {
      category: "Documentation",
      title: "Synk Overview",
      src: "/images/cards/synkoverview.png",
      content: <DummyContent />,
      cardAction: {
        url: "https://docs.synk.ws/",
        openInNewTab: true,
      },
      buttonProps: {
        type: "regular" as "regular",
        text: "Visit Synk.ws",
        url: "https://docs.synk.ws/",
        openInNewTab: true,
      },
    },
    {
      category: "Website",
      title: "Visit Us",
      src: "/images/cards/visitus.png",
      content: <DummyContent />,

      cardAction: {
        url: "https://synk.ws",
        openInNewTab: true,
      },
      buttonProps: {
        type: "regular" as "regular",
        text: "Visit Our Website",
        url: "https://synk.ws",
        openInNewTab: true,
      },
    },
    {
      category: "Etherscan",
      title: "View on Etherscan",
      src: "/images/cards/etherscan.png",
      content: <DummyContent />,
      cardAction: {
        url: "https://etherscan.io/address/0xe5429a0d8751afc5dfb307f9c2e3e1c60837942a",
        openInNewTab: true,
      },
      buttonProps: {
        type: "regular" as "regular",
        text: "View on Etherscan",
        url: "https://etherscan.io/address/0xe5429a0d8751afc5dfb307f9c2e3e1c60837942a",
        openInNewTab: true,
      },
    },
    {
      category: "Dextools",
      title: "Get Our Token",
      src: "/images/cards/dextools.png",
      content: <DummyContent />,

      cardAction: {
        url: "https://www.dextools.io/app/en/ether/pair-explorer/0xe5429a0d8751afc5dfb307f9c2e3e1c60837942a?t=1731292773545",
        openInNewTab: true,
      },
      buttonProps: {
        type: "regular" as "regular",
        text: "Get Our Token",
        url: "https://www.dextools.io/app/en/ether/pair-explorer/0xe5429a0d8751afc5dfb307f9c2e3e1c60837942a?t=1731292773545",
        openInNewTab: true,
      },
    },

    {
      category: "Litepaper",
      title: "Read Our Litepaper",
      src: "/images/cards/whitepaper.png",
      content: <DummyContent />,
      cardAction: {
        url: "https://lite-paper.synk.ws/",
        openInNewTab: true,
      },
      buttonProps: {
        type: "regular" as "regular",
        text: "Read Litepaper",
        url: "https://lite-paper.synk.ws/",
        openInNewTab: true,
      },
    },
  ];

  const cards = data.map((card, index) => (
    <Card key={card.src} card={card} index={index} />
  ));

  return (
    <div className="h-full w-full">
      <div className="min-h-screen h-full w-full flex flex-col space-y-4 bg-black/[0.96] relative overflow-hidden">
        <div
          className="absolute inset-0 bg-cover bg-no-repeat "
          style={{
            backgroundImage: 'url("/images/Ellipse1.png")',
            zIndex: 0,
          }}
        ></div>

        <Spotlight
          className="-top-40 left-0 md:left-60 md:-top-20"
          fill="#22dcd1"
        />

        <div className="w-full pl-[25px]">
          {/* <img src="/images/logo_letter.svg" width={80} /> */}

          <div
            className={`relative z-10 text-left text-white font-logo text-[1.8rem] leading-[100%] tracking-[-0.08em] gradient-stroke`}
          >
            Synk
          </div>
        </div>

        <div className="pt-10 lg:pt-24 flex flex-col">
          <p className="pl-[25px] max-w-7xl mx-auto uppercase text-5xl md:text-6xl lg:text-[4rem] font-bold bg-clip-text text-transparent bg-gradient-to-b from-synkColor/20 to-synkColor/60 inset-x-0">
            You can click on any card to unlock Synk power.
          </p>

          <div className="relative mt-[-35px] z-40">
            <Carousel items={cards} />
          </div>
        </div>

        <StarsBackground />
        <ShootingStars />
      </div>
    </div>
  );
}

const DummyContent = () => {
  return (
    <>
      {[...new Array(3).fill(1)].map((_, index) => {
        return (
          <div
            key={"dummy-content" + index}
            className="bg-[#F5F5F7] dark:bg-neutral-800 p-8 md:p-14 rounded-3xl mb-4"
          >
            <p className="text-neutral-600 dark:text-neutral-400 text-base md:text-2xl font-sans max-w-3xl mx-auto">
              <span className="font-bold text-neutral-700 dark:text-neutral-200">
                The first rule of Apple club is that you boast about Apple club.
              </span>{" "}
              Keep a journal, quickly jot down a grocery list, and take amazing
              class notes. Want to convert those notes to text? No problem.
              Langotiya jeetu ka mara hua yaar is ready to capture every
              thought.
            </p>
            <Image
              src=""
              alt="Synk"
              height="500"
              width="500"
              className="md:w-1/2 md:h-1/2 h-full w-full mx-auto object-contain"
            />
          </div>
        );
      })}
    </>
  );
};
