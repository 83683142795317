import React, { useRef, useEffect, useCallback, useState } from "react";
import RFB from "@countersink/s_novnc";
import { observer } from "mobx-react-lite";
import { runInAction } from "mobx";
import isolatedAppStore from "pages/IsolatedApp/store/isolatedAppStore";
import { Skeleton, Button } from "@nextui-org/react";

interface NoVNCViewerProps {
  url: string;
}

interface ExtendedRFB extends RFB {
  _qualityLevel: number;
  _compressionLevel: number;
  _sendEncodings: () => void;
}

const isMobileDevice = () => {
  if (typeof navigator !== "undefined") {
    return /Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  }
  return false;
};

const NoVNCViewer: React.FC<NoVNCViewerProps> = observer(({ url }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const rfbRef = useRef<RFB | null>(null);
  const isAttemptingConnectionRef = useRef(false);

  const autoAttemptIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const manualAttemptIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const autoReconnectionAttemptsRef = useRef(0);
  const manualReconnectionAttemptsRef = useRef(0);

  const hiddenInputRef = useRef<HTMLInputElement | null>(null);
  const attemptStartTimeRef = useRef<number | null>(null);
  const [isReconnecting, setIsReconnecting] = useState(false);
  const [showReconnectUI, setShowReconnectUI] = useState(false);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);

  const lastTapRef = useRef<number | null>(null);
  const TAP_DELAY = 300;

  const connect = useCallback(() => {
    if (containerRef.current && !isAttemptingConnectionRef.current) {
      console.log("Attempting VNC connection...");
      isAttemptingConnectionRef.current = true;

      if (rfbRef.current) {
        console.log("Disconnecting from RFB instance");
        rfbRef.current.disconnect();
        rfbRef.current = null;
      }

      try {
        const rfb = new RFB(containerRef.current, url, {
          qualityLevel: isolatedAppStore.qualityLevel,
          compressionLevel: isolatedAppStore.compressionLevel,
        });

        const isMobile = isMobileDevice();

        rfb.scaleViewport = true;
        rfb.resizeSession = true;
        rfb.clipViewport = false;
        rfb.dragViewport = true;
        rfb.keyboardEnabled = true;
        rfb.focusOnClick = !isMobile;

        const handleConnect = () => {
          console.log("Connected to VNC");

          runInAction(() => {
            isolatedAppStore.setConnected(true);
          });

          setIsReconnecting(false);
          setShowReconnectUI(false);

          isAttemptingConnectionRef.current = false;

          if (autoAttemptIntervalRef.current) {
            clearInterval(autoAttemptIntervalRef.current);
            autoAttemptIntervalRef.current = null;
            autoReconnectionAttemptsRef.current = 0;
          }

          if (manualAttemptIntervalRef.current) {
            clearInterval(manualAttemptIntervalRef.current);
            manualAttemptIntervalRef.current = null;
            manualReconnectionAttemptsRef.current = 0;
          }

          isolatedAppStore.stopLoadingProgress();

          const start = Math.floor(isolatedAppStore.loadingProgress);
          const end = 100;
          const duration = isolatedAppStore.delayAfterConnection;
          const startTime = Date.now();

          const animateProgress = () => {
            const elapsed = Date.now() - startTime;
            if (elapsed < duration) {
              const progress = start + ((end - start) * elapsed) / duration;
              runInAction(() => {
                isolatedAppStore.setLoadingProgress(progress);
              });
              requestAnimationFrame(animateProgress);
            } else {
              runInAction(() => {
                isolatedAppStore.setLoadingProgress(100);
                isolatedAppStore.setLoading(false);
              });
            }
          };

          animateProgress();
        };

        const handleDisconnect = () => {
          console.log("Disconnected from VNC");

          runInAction(() => {
            isolatedAppStore.setConnected(false);
          });

          isAttemptingConnectionRef.current = false;

          startReconnectionAttempts();
        };

        const handleError = (e: any) => {
          console.error("RFB error:", e);
          isAttemptingConnectionRef.current = false;

          setIsReconnecting(false);
          setShowReconnectUI(true);
        };

        rfb.addEventListener("connect", handleConnect);
        rfb.addEventListener("disconnect", handleDisconnect);
        rfb.addEventListener("securityfailure", (e: any) => {
          console.error("Security failure", e.detail.status);
          isAttemptingConnectionRef.current = false;
        });
        rfb.addEventListener("error", handleError);

        rfbRef.current = rfb;

        const canvas = rfbRef.current._canvas;
        if (canvas) {
          canvas.addEventListener("touchend", handleCanvasTouchEnd);
          canvasRef.current = canvas;
        }

        isolatedAppStore.sendClipboardText = (text: string) => {
          rfb.clipboardPasteFrom(text);
        };
      } catch (error) {
        console.error("Error creating RFB instance:", error);
        isAttemptingConnectionRef.current = false;

        setIsReconnecting(false);
        setShowReconnectUI(true);
      }
    } else {
      console.error(
        "containerRef.current is null or another connection is already open"
      );
    }
  }, [url]);

  const startConnectionAttempts = useCallback(() => {
    attemptStartTimeRef.current = Date.now();
    connect();
    autoAttemptIntervalRef.current = setInterval(() => {
      if (!isolatedAppStore.connected && !isAttemptingConnectionRef.current) {
        const elapsed =
          Date.now() - (attemptStartTimeRef.current || Date.now());
        if (elapsed >= isolatedAppStore.MAX_ATTEMPT_DURATION) {
          if (autoAttemptIntervalRef.current) {
            clearInterval(autoAttemptIntervalRef.current);
            autoAttemptIntervalRef.current = null;
          }
          runInAction(() => {
            isolatedAppStore.remainingTime = 0;
            isolatedAppStore.isLoading = false;
          });
        } else {
          connect();

          runInAction(() => {
            if (isolatedAppStore.loadingProgress < 90) {
              isolatedAppStore.incrementProgress(2);
            }
          });
        }
      }
    }, 1000);
  }, [connect]);

  const attemptReconnect = (isManual: boolean) => {
    const attemptsRef = isManual
      ? manualReconnectionAttemptsRef
      : autoReconnectionAttemptsRef;
    attemptsRef.current += 1;
    console.log(
      `Reconnection attempt ${attemptsRef.current} (isManual: ${isManual})`
    );
    connect();
  };

  const startReconnectionAttempts = useCallback(
    (isManual = false) => {
      const attemptsRef = isManual
        ? manualReconnectionAttemptsRef
        : autoReconnectionAttemptsRef;
      const intervalRef = isManual
        ? manualAttemptIntervalRef
        : autoAttemptIntervalRef;

      if (intervalRef.current !== null) {
        return;
      }

      attemptsRef.current = 0;

      setIsReconnecting(true);

      attemptReconnect(isManual);

      intervalRef.current = setInterval(() => {
        if (!isolatedAppStore.connected && !isAttemptingConnectionRef.current) {
          if (attemptsRef.current < 2) {
            attemptReconnect(isManual);
          } else {
            if (intervalRef.current) {
              clearInterval(intervalRef.current);
              intervalRef.current = null;
            }

            setIsReconnecting(false);

            setShowReconnectUI(true);
          }
        }
      }, 500);
    },
    [attemptReconnect, isolatedAppStore.connected]
  );

  useEffect(() => {
    if (rfbRef.current) {
      const rfb = rfbRef.current as ExtendedRFB;
      rfb._qualityLevel = isolatedAppStore.qualityLevel;
      rfb._compressionLevel = isolatedAppStore.compressionLevel;
      rfb._sendEncodings();
    }
  }, [isolatedAppStore.qualityLevel, isolatedAppStore.compressionLevel]);

  useEffect(() => {
    startConnectionAttempts();

    return () => {
      if (rfbRef.current) {
        if (canvasRef.current) {
          canvasRef.current.removeEventListener(
            "touchend",
            handleCanvasTouchEnd
          );
        }
        rfbRef.current.disconnect();
        rfbRef.current = null;
      }
      if (autoAttemptIntervalRef.current) {
        clearInterval(autoAttemptIntervalRef.current);
        autoAttemptIntervalRef.current = null;
      }
      if (manualAttemptIntervalRef.current) {
        clearInterval(manualAttemptIntervalRef.current);
        manualAttemptIntervalRef.current = null;
      }
    };
  }, [url, startConnectionAttempts]);

  useEffect(() => {
    if (
      isolatedAppStore.remainingTime !== null &&
      isolatedAppStore.remainingTime <= 0 &&
      rfbRef.current
    ) {
      rfbRef.current.disconnect();
      rfbRef.current = null;
    }
  }, [isolatedAppStore.remainingTime]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        if (
          !isolatedAppStore.connected &&
          !isAttemptingConnectionRef.current &&
          autoAttemptIntervalRef.current === null
        ) {
          if (!showReconnectUI) {
            startReconnectionAttempts();
          }
        }
      } else {
        if (rfbRef.current) {
          rfbRef.current.disconnect();
          rfbRef.current = null;
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [startReconnectionAttempts, showReconnectUI]);

  const handleReconnect = () => {
    if (
      isolatedAppStore.remainingTime !== null &&
      isolatedAppStore.remainingTime > 0
    ) {
      if (manualAttemptIntervalRef.current) {
        clearInterval(manualAttemptIntervalRef.current);
        manualAttemptIntervalRef.current = null;
      }

      setShowReconnectUI(false);

      startReconnectionAttempts(true);
    } else {
    }
  };

  const keySymMap: { [key: string]: number } = {
    Backspace: 0xff08,
    Tab: 0xff09,
    Enter: 0xff0d,
    Shift: 0xffe1,
    Control: 0xffe3,
    Alt: 0xffe9,
    Pause: 0xff13,
    CapsLock: 0xffe5,
    Escape: 0xff1b,
    PageUp: 0xff55,
    PageDown: 0xff56,
    End: 0xff57,
    Home: 0xff50,
    ArrowLeft: 0xff51,
    ArrowUp: 0xff52,
    ArrowRight: 0xff53,
    ArrowDown: 0xff54,
    Insert: 0xff63,
    Delete: 0xffff,
  };

  const handleCanvasTouchEnd = (e: TouchEvent) => {
    e.preventDefault();
    e.stopPropagation();

    const now = Date.now();
    if (lastTapRef.current && now - lastTapRef.current < TAP_DELAY) {
      if (hiddenInputRef.current) {
        hiddenInputRef.current.focus();
      }
      lastTapRef.current = null;
    } else {
      lastTapRef.current = now;
    }
  };

  const handleInputFocus = () => {
    setIsKeyboardOpen(true);
  };

  const handleInputBlur = () => {
    setIsKeyboardOpen(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (rfbRef.current) {
      const key = e.key;
      const keysym = keySymMap[key] || key.charCodeAt(0);
      rfbRef.current.sendKey(keysym, key, true);
      e.preventDefault();
    }
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (rfbRef.current) {
      const key = e.key;
      const keysym = keySymMap[key] || key.charCodeAt(0);
      rfbRef.current.sendKey(keysym, key, false);
      e.preventDefault();
    }
  };

  return (
    <div
      ref={containerRef}
      className="relative w-full h-full overflow-hidden rounded-[15px] border border-primary"
    >
      <input
        ref={hiddenInputRef}
        type="text"
        style={{
          position: "absolute",
          opacity: 0,
          height: "1px",
          width: "1px",
          border: "none",
          outline: "none",
          zIndex: -1,
        }}
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyUp}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
      />

      {isReconnecting && (
        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 z-20">
          <Skeleton className="w-full h-full" />
        </div>
      )}

      {showReconnectUI && (
        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 z-20">
          <div className="text-center text-white">
            <p className="font-bold bg-gray-100/[0.1] text-white-500 px-4 py-1.5 rounded-md">
              Oops, you've lost the connection.
            </p>
            {isolatedAppStore.remainingTime !== null &&
            isolatedAppStore.remainingTime > 0 ? (
              <Button
                onClick={handleReconnect}
                className="mt-2 bg-white text-black"
                isLoading={isReconnecting}
                radius="md"
              >
                Reconnect
              </Button>
            ) : (
              <p>Your session has expired.</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
});

export default NoVNCViewer;
