export const ROUTES = {
  HOME: "/",
  COUNTDOWN: "/countdown",
  OS: "/os",
  ISOLATEDAPP: "/app/firefox/:sessionId",
  PRIVACY_EXTENSION: "/PrivacyPolicy/Extension",
  DNS_OVERRIDE: "/safety/DNS_OVERRIDE",
  SEARCHENGINE: "/s",
  SYNKINT: "/SynkINT",
  OSYNK: "/OSYNK",
  SYNKINT_CHAT: "/SynkINT/chat",
  START_FX: "/startFirefox",
  RATE_LIMIT: "/rate-limit",
  ERROR_PAGE: "/whoopsie",
  NOT_FOUND: "*",

  // TEST: "/test",
  // TEST2: "/test2",
  // TEST3: "/test3",
  // TEST4: "/test4",
  // TEST5: "/test5",
  // TEST6: "/test6",
  // TEST7: "/test7",
  // TEST8: "/test8",
  // TEST9: "/test9",
  // TEST10: "/test10",
};
